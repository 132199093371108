'use strict';

/**
 * Setup CSRF token for AJAX requests
 */

// Require jQuery. (CommonJS compatible if installed with NPM.)
// You must use this syntax to prevent issues, especially w/ Bootstrap.
window.$ = window.jQuery = require('jquery');

// Do something after DOM is ready
$(function () {

    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }
    });

});
